import React from 'react'
import { Link } from 'react-router-dom'

function CourseContentSeo() {
  return (
    <>
      <div className='container py-4'>
        <div className='row'>
          <Link className='text-decoration-none text-dark mb-4' to='/lms-dashboard'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>Go Back to Dashboard</p></Link>

          <h5 className='fw-bold mb-3'>Search Engine Optimization</h5>
        </div>

        <div className='row'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/yojpWSDiMO4?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/zbDDYdS_Km4?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/zfFeK98ysFQ?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/mW0N2H8wIE8?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/dHhHrDm_6p4?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/Wnlc-hHlcW8?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Up0iAZNH1jE?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/PPhg8WuUISQ?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/0_-9389d820?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/CT8Gm5HpG4E?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/HZOsBTH9-3Y?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/ETMSoqr_ph0?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/fyLS28rjKOA?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/nck8v1Ol0CE?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/H-YKUMZdANg?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/ydVHxHVKreg?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/kmLjyLm2-WQ?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/GVIHOm08iK8?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/OW3rh-BdMaA?list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" allowFullScreen={true}></iframe>
          </div>

        </div>
      </div>


    </>
  )
}

export default CourseContentSeo