import React from 'react'
import { Link } from 'react-router-dom'

function CourseContentDigitalMarketing() {
  return (
    <>
      <div className='container py-4'>
        <div className='row'>
          <Link className='text-decoration-none text-dark mb-4' to='/lms-dashboard'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>Go Back to Dashboard</p></Link>

          <h5 className='fw-bold mb-3'>Digital Marketing</h5>
        </div>

        <div className='row'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/MKw7T3uRr4k?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/0iy0sbxRENc?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/UmOpzbOBiXU?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/LMbTgHSWaIE?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/Ley3ARAh6MM?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/2YLqajJa7Ss?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/SKhpz_9rrks?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/e_GLFfZrOqM?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/UvLbrWXHIgs?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/6u5TWYU0nNI?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/SHZsynWpetw?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/dqhzQQwku80?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/aTLuVHs7TT4?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/xPe4C8zD7_o?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/qdM_6IGowvM?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/gD04ZFVby0I?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/0D4YQVm1ji0?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/J4zgVEQYlf8?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/z2_1ZGA1DaY?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/EoMrxpNiiQw?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/4Fcy6bm_BJg?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/MlIrTdG3gpI?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/O77bUMho-RQ?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/M_a9smJ9hME?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/lrzQONl2UCg?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/C9gzA1i-Jf4?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/Mma6mjX0mks?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/n4nK322D3pk?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/VlHKslFItLo?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/KZUNN5x70u0?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/TgHZmTrneuA?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/2kCRPN42WXs?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/vdxbgZHpE6A?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Q3VqjsQBAB8?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/zKloHtqvb7g?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/UdqyFHk60tc?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/JhKA2lWcIyk?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/04I60q_rCao?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/sqFXGZX-hj8?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/DJBt4R4SHJ4?list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" allowFullScreen={true}></iframe>
          </div>

        </div>
      </div>
    </>
  )
}

export default CourseContentDigitalMarketing