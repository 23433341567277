import React from 'react'
import { Link } from 'react-router-dom'

function LMS() {
  return (
    <>
      <div className='container py-md-3 py-0'>
        <div className='row bg-purple text-white py-3 px-3 mx-3 mt-3'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <i class="fa-solid fa-user-graduate fs-2"></i>
            </div>

            <div>
              <Link className='text-decoration-none text-white' to='/lms-login'><span className='fs-xsm'>Log Out<i class="fa-solid fa-power-off cursor-pointer ms-2"></i></span></Link>
            </div>
          </div>

          {/* <i class="fa-solid fa-award fs-1"></i> */}
          <h6 className='fw-600 mb-0 mt-2'>Welcome to Labbaik Learning Management System</h6>
          <p className='fs-xsm mb-0 mt-1'>Choose any course from the list below and start learning today completely free!</p>

          <Link className='text-decoration-none text-white' to='/'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>labbaikitservices.com</p></Link>
        </div>


        <div className='row mt-md-4 mt-4 mx-1'>
          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
              <Link className='text-decoration-none text-dark' to='/web-development-course'>
                <i class="fa-solid fa-laptop-code"></i>
                <p className='pt-4 mb-0 fw-bold'>Web Development</p>
                <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>M. Abdullah & Zahoor Ahmad</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>23hrs</label>
                </div>
              </Link>
            </div>
          </div>

          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
              <Link className='text-decoration-none text-dark' to='/graphic-designing-course'>
                <i class="fa-solid fa-palette"></i>
                <p className='pt-4 mb-0 fw-bold'>Graphic Designing</p>

                <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>Malik Adil</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>14hrs</label>
                </div>
              </Link>

            </div>
          </div>

          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
              <Link className='text-decoration-none text-dark' to='/digital-marketing-course'>
                <i class="fa-solid fa-ranking-star"></i>
                <p className='pt-4 mb-0 fw-bold'>Digital Marketing</p>

                <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>Ali Masoomi</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>43hrs</label>
                </div>
              </Link>
            </div>
          </div>

          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
              <Link className='text-decoration-none text-dark' to='/wordpress-development-course'>
                <i class="fa-solid fa-file-code"></i>
                <p className='pt-4 mb-0 fw-bold'>WordPress Development</p>
                <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>Adnan Sami</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>5hrs</label>
                </div>
              </Link>
            </div>
          </div>

        </div>

        <div className='row mt-md-4 mt-0 mx-1'>
          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
            <Link className='text-decoration-none text-dark' to='/content-writing-course'>
              <i class="fa-solid fa-file-pen"></i>
              <p className='pt-4 mb-0 fw-bold'>Content Writing</p>
              <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>Dr. Rizwan Siddiqui</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>13hrs</label>
                </div>
              </Link>
            </div>
          </div>

          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
            <Link className='text-decoration-none text-dark' to='/seo-course'>
              <i class="fa-solid fa-coins"></i>
              <p className='pt-4 mb-0 fw-bold'>SEO</p>
              <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>Ms. Hamna Wahid</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>8.6hrs</label>
                </div>
              </Link>
            </div>
          </div>

          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
            <Link className='text-decoration-none text-dark' to='/daraz-ecommerce-course'>
              <i class="fa-solid fa-store"></i>
              <p className='pt-4 mb-0 fw-bold'>Daraz Ecommerce</p>
              <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>Mehtab Ahmad</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>10hrs</label>
                </div>
              </Link>
            </div>
          </div>

          <div className='col-md col-12 mb-md-0 mb-4'>
            <div className='course-item-box-for-content-page'>
            <Link className='text-decoration-none text-dark' to='/lms-dashboard'>
              <i class="fa-solid fa-business-time"></i>
              <p className='pt-4 mb-0 fw-bold'>Freelancing</p>
              <div className='d-flex align-items-center justify-content-between'>
                  <label className='fs-xsm'><i class="fa-solid fa-user me-1 fs-xsm"></i>Launching Soon</label>
                  <label className='fs-xsm'><i class="fa-solid fa-clock me-1 fs-xsm"></i>0hr</label>
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default LMS