import React from 'react'
import { Link } from 'react-router-dom'

function CourseContentWordPress() {
    return (
        <>
            <div className='container py-4'>
                <div className='row'>
                    <Link className='text-decoration-none text-dark mb-4' to='/lms-dashboard'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>Go Back to Dashboard</p></Link>

                    <h5 className='fw-bold mb-3'>WordPress Development</h5>
                </div>

                <div className='row'>
                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/GQfbT-IfiOA?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>

                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/OW3bzjsnQTM?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>

                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/9ZfEq9D5HcU?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>
                </div>

                <div className='row mt-md-3 mt-0'>
                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/u1-cHXehRzM?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>

                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/_MZq9XS8OqI?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>

                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/d--S2wRtU0?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>
                </div>

                <div className='row mt-md-3 mt-0'>
                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/KTfbOQE1tv4?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>

                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/xdA3P9PxrrU?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>

                    <div className='col-md-4 col-12'>
                        <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/SJhVKa2nM_U?list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" allowFullScreen={true}></iframe>
                    </div>
                </div>
            </div>



        </>
    )
}

export default CourseContentWordPress