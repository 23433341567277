import React from 'react'
import { Link } from 'react-router-dom'

function CourseContentDaraz() {
  return (
    <>
      <div className='container py-4'>
        <div className='row'>
          <Link className='text-decoration-none text-dark mb-4' to='/lms-dashboard'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>Go Back to Dashboard</p></Link>

          <h5 className='fw-bold mb-3'>Daraz Ecommerce</h5>
        </div>

        <div className='row'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/sUkjbmY81UE?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/vsr6ZiItTso?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/MUO6Xo43jkw?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/vfM6pxwq60Q?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/7AlfrxKRU5o?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/WDHQKP3x3z0?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/zZwLymZi7jM?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/ca9D2DfxbhY?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/llR8iFXu-WI?list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" allowFullScreen={true}></iframe>
          </div>
        </div>
      </div>


    </>
  )
}

export default CourseContentDaraz