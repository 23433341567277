import React from 'react'
import { Link } from 'react-router-dom'

function CourseContentContentWriting() {
  return (
    <>
      <div className='container py-4'>
        <div className='row'>
          <Link className='text-decoration-none text-dark mb-4' to='/lms-dashboard'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>Go Back to Dashboard</p></Link>

          <h5 className='fw-bold mb-3'>Content Writing</h5>

          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Dccw6qj881U?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/c8IvgPFWBJQ?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/CtdApYqv2IQ?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/vp33eym34Is?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/J5-LYc6GZtg?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/tNI7Oc15anA?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/ngY19i6gfLw?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/jxy1DGtqZJ0?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/JeNoB665a0c?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/J5If4HF7-Zo?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/TbC_XLChA8k?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/9H-I-RwJVnI?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Vphwp5KlG8Y?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/M7bRCzc23tM?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/ePnKzin9p_g?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/ePnKzin9p_g?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/WvxS8d3SFFU?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/3Bt9kKfsLAg?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/m7gpToCEsDI?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/Ft9ojmJiN-4?list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" allowFullScreen={true}></iframe>
          </div>

          {/* <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="" allowFullScreen={true}></iframe>
          </div> */}
        </div>

        {/* <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="" allowFullScreen={true}></iframe>
          </div>
        </div> */}
      </div>
    </>


  )
}

export default CourseContentContentWriting