import React from 'react'
import { Link } from 'react-router-dom'

function CourseContentGraphicDesigning() {
  return (
    <>
      <div className='container py-4'>
        <div className='row'>
          <Link className='text-decoration-none text-dark mb-4' to='/lms-dashboard'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>Go Back to Dashboard</p></Link>

          <h5 className='fw-bold mb-3'>CANVA Graphic Designing</h5>
          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Q2Mxj7pbLf8" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/Q2Mxj7pbLf8" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/Q2Mxj7pbLf8" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/0xJfV-SnUb4?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/rekZYjCekI8?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/TTMTpGjBwyI?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Ao6k01RdFXU?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/lb6ceDs9Wok?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/a9s3q7Rp2wo?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/cgBKYVU_z_Q?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/FG9b7npJXr8?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/r1YBKqSiVuY?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/pFtInavfgKc?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/eV-ogJIlAU0?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/n14kA6JtTzY?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/gma_ym7RW0c?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/derv9h0oEaM?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/I6fhvX7FHwE?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            {/*--height 564px */}
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/6uwwqa76qGQ?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/2oh4vj7uvC0?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
          <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/qZNAUc_fVjM?list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" allowFullScreen={true}></iframe>
          </div>
        </div>

        {/* <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="" allowFullScreen={true}></iframe>
          </div>
        </div> */}
      </div>

    </>
  )
}

export default CourseContentGraphicDesigning