import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './../WebLandingPage/WebLandingPage.css';
import codingIllustration from './../../../src/assets/images/coding-illustration.png';
import upskill from './../../../src/assets/images/228 (2).png';
import webDevelopment from './../../../src/assets/images/web-development.jpg';
import graphicDesigning from './../../../src/assets/images/graphic-designing.jpg';
import digitalMarketing from './../../../src/assets/images/digital-marketing.jpg';
import contentWriting from './../../../src/assets/images/content-writing.jpg';
import wordpressDevelopment from './../../../src/assets/images/wordpress-development.jpg';
import darazEcommerce from './../../../src/assets/images/daraz-ecommerce.jpg';
import seo from './../../../src/assets/images/seo.jpg';
import freelancing from './../../../src/assets/images/freelancing.jpg';
import malikAdil from './../../../src/assets/images/Malik-Adil.jpg';
import drRizwan from './../../../src/assets/images/Dr-Rizwan.jpg';
import mAbdullah from './../../../src/assets/images/Abdullah.jpg';
import adnanSami from './../../../src/assets/images/Adnan-Sami.jpg';
import mZahoor from './../../../src/assets/images/Zahoor.jpg';
import aliMasoomi from './../../../src/assets/images/Ali-Masoomi.jpg';
import mMehtab from './../../../src/assets/images/Mehtab.jpg';
import msHamna from './../../../src/assets/images/female-avatar.png';
import { Link } from 'react-router-dom';

function WebLandingPage() {
    return (
        <>
            <Navbar expand="lg" className="bg-purple navbar-dark">
                <Container>
                    <Navbar.Brand href="#home" className='fw-bold'>Labbaik IT Services</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#courses">Courses</Nav.Link>
                            <Nav.Link as={Link} to="/lms-login">LMS</Nav.Link>
                            <Nav.Link href="#feedback">Feedback</Nav.Link>
                            <Nav.Link href="#contactUs">Contact Us</Nav.Link>
                            {/* <Nav.Link href="#home">Home</Nav.Link> */}
                            {/* <Nav.Link href="#link">Link</Nav.Link> */}
                            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className='container p-res-x p-res-y' id="home">
                <div className='row align-items-center'>
                    <div className='col-md-7'>
                        <h2>Welcome to Official Web Portal <br></br><strong>Labbaik IT Services</strong></h2>
                        <p>Pakistan's first State of the Art full fledged unique Tech Up-Skill Program</p>
                        <p>Empowering the youth with advanced technical skills is our mission, enabling them to make impactful contributions to Islam, Pakistan, and the well-being of the community.</p>
                    </div>

                    <div className='col-md-5 d-flex justify-content-center'>
                        <img className='w-100' src={codingIllustration} />
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <marquee>
                            <label className='labels-style'>Web Development</label>
                            <label className='labels-style'>Graphic Desiging</label>
                            <label className='labels-style'>Digital Marketing</label>
                            <label className='labels-style'>WordPress Development</label>
                            <label className='labels-style'>Search Engine Optimisation (SEO)</label>
                            <label className='labels-style'>Content Writing</label>
                            <label className='labels-style'>Daraz Ecommerce</label>
                        </marquee>
                    </div>
                </div>
            </div>

            <div className='container my-5'>
                <div className='row align-items-center bg-purple-light b-rounded-3 mx-res py-4'>
                    <div className='col-md-9 px-4'>
                        <h4 className='fw-bold'>Empower Your Mind, Transform the Digital World!</h4>
                        <p>By mastering IT skills, you can become a catalyst for technological advancement and societal progress.</p>
                    </div>

                    <div className='col-md-3 text-center'>
                        <img className='w-75' src={upskill} />
                    </div>
                </div>
            </div>

            <div className='container p-res-x'>
                <div className='row my-5 py-5'>
                    <div className='col-md-4'>
                        <h4 className='fw-bold'>Explore<br></br> Our Courses</h4>
                        <p>Explore our diverse range of courses and elevate your career to the next level.</p>
                    </div>

                    <div className='col-md-8'>
                        <div className='row'>
                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-laptop-code"></i>
                                    <p>Web Development</p>
                                </div>
                            </div>

                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-palette"></i>
                                    <p>Graphic Designing</p>
                                </div>
                            </div>

                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-ranking-star"></i>
                                    <p>Digital Marketing</p>
                                </div>
                            </div>

                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-file-code"></i>
                                    <p>WordPress Development</p>
                                </div>
                            </div>

                        </div>

                        <div className='row mt-md-4 mt-0'>
                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-file-pen"></i>
                                    <p>Content Writing</p>
                                </div>
                            </div>

                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-coins"></i>
                                    <p>SEO</p>
                                </div>
                            </div>

                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-store"></i>
                                    <p>Daraz Ecommerce</p>
                                </div>
                            </div>

                            <div className='col-md col-6 mb-md-0 mb-4'>
                                <div className='course-item-box'>
                                    <i class="fa-solid fa-business-time"></i>
                                    <p>Freelancing</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid my-4 p-res-x py-4 py-md-0 bg-purple text-white'>
                <div className='row align-items-center py-5'>
                    <div className='col-md-6 pb-md-0 pb-5 px-md-5'>
                        <h3 className='fw-bold'>
                            Barho Roshan Mustaqbil K Liye
                        </h3>
                        <p className='mb-0'>Pakistan's first State of the Art full fledged unique Tech Up-Skill Program <span className='fw-bold text-green'>Barho Roshan Mustaqbil k Liye</span> in collaboration with KHREWS & LETS launched by Labbaik IT Services.</p>
                    </div>

                    <div className='col-md'>
                        <p className='fw-600 fs-2 mb-0'>12,000+</p>
                        <p className='mb-0'>Applications Received</p>
                    </div>

                    <div className='col-md'>
                        <p className='fw-600 fs-2 mb-0'>3000+</p>
                        <p className='mb-0'>Females Applicants</p>
                    </div>

                    <div className='col-md'>
                        <p className='fw-600 fs-2 mb-0'>5000+</p>
                        <p className='mb-0'>University Students</p>
                    </div>
                </div>
            </div>

            <div className='container py-5 my-5'>
                <h1 className='text-center fw-bold pb-2'>Meet the Super Team</h1>
                <p className='text-center pb-5'>Alhumdulilah As a dedicated team, we are working completely free of charge, committed to the noble mission of empowering the Ummah to face future challenges, particularly in the field of Information Technology.</p>
                <div className='row'>
                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={malikAdil} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>Malik Adil</p>
                            <label className='fs-sm'>President</label>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={drRizwan} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>Dr. Rizwan</p>
                            <label className='fs-sm'>Supervisor</label>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={mAbdullah} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>M. Abdullah</p>
                            <label className='fs-sm'>Instructor</label>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={adnanSami} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>Adnan Sami</p>
                            <label className='fs-sm'>Instructor</label>
                        </div>
                    </div>
                </div>

                <div className='row mt-md-5 mt-0'>
                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={mZahoor} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>M. Zahoor</p>
                            <label className='fs-sm'>Instructor</label>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={aliMasoomi} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>Ali Masoomi</p>
                            <label className='fs-sm'>Instructor</label>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={mMehtab} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>M. Mehtab</p>
                            <label className='fs-sm'>Instructor</label>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='team-card'>
                            <img src={msHamna} />
                            <p className='fw-bold mb-0 mt-3 text-purple'>Ms. Hamna</p>
                            <label className='fs-sm'>Instructor</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container py-4 my-5 p-res-x' id="courses">
                <h1 className='text-center fw-bold pb-5'>Launch Your Journey</h1>
                <div className='row'>
                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={webDevelopment} />
                            <h5 className='fw-bold pt-3'>Web Development</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>M. Abdullah & M. Zahoor</p>
                            <a href="https://www.youtube.com/watch?v=8P159yILskg&list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" target='blank'><label className='btn-style'>Start Course Free</label></a>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={graphicDesigning} />
                            <h5 className='fw-bold pt-3'>Graphic Designing</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>Malik Adil</p>
                            <a href="https://www.youtube.com/watch?v=0xJfV-SnUb4&list=PL8WtteUy9eZJfO-nafyIZP_eir1roGPpG" target='blank'><label className='btn-style'>Start Course Free</label></a>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={digitalMarketing} />
                            <h5 className='fw-bold pt-3'>Digital Marketing</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>Ali Masoomi</p>
                            <a href="https://www.youtube.com/watch?v=MKw7T3uRr4k&list=PL8WtteUy9eZJF-aOZKr1g9-eSsik6oZou" target="blank"><label className='btn-style'>Start Course Free</label></a>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={wordpressDevelopment} />
                            <h5 className='fw-bold pt-3'>WordPress</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>Adnan Sami</p>
                            <a href="https://www.youtube.com/watch?v=GQfbT-IfiOA&list=PL8WtteUy9eZJYr-K98AYw4EQeqwfYO1rV" target="blank"><label className='btn-style'>Start Course Free</label></a>
                        </div>
                    </div>
                </div>

                <div className='row mt-md-4 mt-0'>
                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={contentWriting} />
                            <h5 className='fw-bold pt-3'>Content Writing</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>Dr. Rizwan</p>
                            <a href="https://www.youtube.com/watch?v=Dccw6qj881U&list=PL8WtteUy9eZLJi1M8dcL9lqoRk5A6b56o" target="blank"><label className='btn-style'>Start Course Free</label></a>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={darazEcommerce} />
                            <h5 className='fw-bold pt-3'>Daraz Ecommerce</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>M. Mehtab</p>
                            <a href="https://www.youtube.com/watch?v=sUkjbmY81UE&list=PL8WtteUy9eZLlcTKLUi73aFdVR9LfpnPV" target="blank"><label className='btn-style'>Start Course Free</label></a>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={seo} />
                            <h5 className='fw-bold pt-3'>SEO</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>Ms. Hamna</p>
                            <a href="https://www.youtube.com/watch?v=yojpWSDiMO4&list=PL8WtteUy9eZKTxbiEoxFP9k3ExehPVpVQ" target="blank"><label className='btn-style'>Start Course Free</label></a>
                        </div>
                    </div>

                    <div className='col-md mb-md-0 mb-5'>
                        <div className='course-card'>
                            <img src={freelancing} />
                            <h5 className='fw-bold pt-3'>Freelaning</h5>
                            <p className='fs-sm'><i class="fa-solid fa-user me-2"></i>-</p>
                            <label className='btn-style'>Upcoming Course</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid mt-4 mb-0 py-4 px-3 bg-purple-light' id="feedback">
                <div className='row px-5 align-items-center'>
                    <div className='col-md py-5'>
                        <h3 className='fw-bold text-center'>
                            Students Feedback
                        </h3>
                    </div>
                </div>

                <div className='col-md'>
                    <marquee>
                        <div className='d-flex algin-items-center'>
                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Maria Irshad</p>
                                <p className='mb-2 fs-xsm'>Content Writing</p>
                                <p className='mb-0 fs-sm max-length'>Labbaik IT Services is a marvelous and wonderful Organization. It boosts my knowledge, and the whole faculty replies and gives feedback to me as soon as possible. Furthermore, Sir Rizwan Siddiqui guided me step by step during the whole period and after completion of the course. Therefore, my decision to join Labbaik Platform is very wonderful for me.</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Saba Liaqat Ali</p>
                                <p className='mb-2 fs-xsm'>Web Development</p>
                                <p className='mb-0 fs-sm'>I'm personally highly thankful for  this
                                    platform BRMKL for providing such demanding variety of courses for which others charge high fees. I enjoy learing form the highly dedicted and expert my tutor Sir Zahoor.. Although it is a short time course and my course  required more than that time but the content was very comprehensive, we learned a lot of things  that will surely guide us to pursue my journey and we surely practice it as well Insha Allah.
                                </p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Hamza Ali</p>
                                <p className='mb-2 fs-xsm'>SEO</p>
                                <p className='mb-0 fs-sm'>SEO course bohat informative aur practical tha, Jis trha BRMKL yeh courses krwa rha hy is trha koi or idarah nhi krwa rha. Ye waqai qabil e tehseen baat hy.
                                </p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Mufaza Rani</p>
                                <p className='mb-2 fs-xsm'>Graphic Desiging</p>
                                <p className='mb-0 fs-sm'>One of the best platforms for learning and achieving your dream goals, teachers are really nice and they are ready to help out. I'm greatly impressed by LITS and appreciate the LITS team working 24/7.</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Muhammad Salman</p>
                                <p className='mb-2 fs-xsm'>Content Writing</p>
                                <p className='mb-0 fs-sm'>Excellent</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Alina Sohail Butt</p>
                                <p className='mb-2 fs-xsm'>Digital Marketing</p>
                                <p className='mb-0 fs-sm'>Session was superb, learned So much, Ideal for what was needed.☺️
                                    V good and professional mentor (Sir Ali). I learned and found this course most helpful.
                                    Thank you So much ☺️
                                </p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Muhammad Owais Raza</p>
                                <p className='mb-2 fs-xsm'>Web Development</p>
                                <p className='mb-0 fs-sm'>I thoroughly enjoyed the course. The instructors were exceptional, and their teaching methods were top-notch. They explained complex concepts in a clear and concise manner, making it easy to understand and absorb. The course material was well-structured, and the pace was perfect. I feel confident and well-equipped with the skills and knowledge gained. Thanks to the instructor (Sir Zahoor Ahmad) and Labbaik IT Services for a fantastic learning experience.</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Amna Munir</p>
                                <p className='mb-2 fs-xsm'>Graphic Designing</p>
                                <p className='mb-0 fs-sm'>A good experience with this platform. Instructor is very polite, kind ,helping,
concerned and responsive.
</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </marquee>
                </div>

                <div className='mb-5'>
                    <marquee>
                        <div className='d-flex algin-items-center'>
                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Muhammad Ahmad</p>
                                <p className='mb-2 fs-xsm'>Graphic Designing</p>
                                <p className='mb-0 fs-sm'>First of all I'm thankful to KHREWS and my respectable teacher Adil Malik because now I'm able to create those very designs which seem difficult, that's my achievement.
                                    Stay blessed ❤️❤️
                                </p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    {/* <i class="fa-solid fa-star"></i> */}
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Iqra Shahid</p>
                                <p className='mb-2 fs-xsm'>Graphic Designing</p>
                                <p className='mb-0 fs-sm'>This course was a great investment of our time.We have learned all about Canva from scratch to advance.It was beyond our expectations. Now we feel very confident and encouraged in our design abilities,and we hope to work on Fiverr in the future.

                                    Thanks to all the LITS team, especially our worthy instructor who guided us properly.
                                </p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Abdul Salam</p>
                                <p className='mb-2 fs-xsm'>Graphic Designing</p>
                                <p className='mb-0 fs-sm'>Excellent</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    {/* <i class="fa-solid fa-star"></i> */}
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Hassan Rehman</p>
                                <p className='mb-2 fs-xsm'>Daraz Ecommerce</p>
                                <p className='mb-0 fs-sm'>All community members teachers are very well  I like their job especially their hardworking for Deen islam I always stand with them   I always support KHREWS Community
                                    Pakistan Zindabad
                                </p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Muhammad Ali</p>
                                <p className='mb-2 fs-xsm'>Graphic Designing</p>
                                <p className='mb-0 fs-sm'>The graphic designing course at Labbaik IT Services exceeded my expectations. The comprehensive curriculum and hands-on projects have significantly enhanced my design skills. I thoroughly enjoyed the graphic designing course. The instructors were knowledgeable, approachable, and always ready to help.</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    {/* <i class="fa-solid fa-star"></i> */}
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>M Aslam</p>
                                <p className='mb-2 fs-xsm'>Graphic Designing</p>
                                <p className='mb-0 fs-sm'>Your guidance and feedback have been invaluable to my growth as a designer. Your enthusiasm and passion for design are contagious and inspire us to push our creative boundaries. Your ability to break down complex design concepts into understandable lessons is impressive. Your patience and encouragement have helped me build confidence in my design skills. Acknowledge the teacher's impact, dedication, and effectiveness in teaching graphic design, and show appreciation for their guidance and support. Thanks TO KHREWS AND OUR BEST TEACHER "ADIL MALIK".</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Saliha Sarwar</p>
                                <p className='mb-2 fs-xsm'>Graphic Designing</p>
                                <p className='mb-0 fs-sm'>It is a top quality course. Keep going, we need this type of course to improve our studies. Excellent. Thank you sir..You've not only taught me valuable lessons in Graphic designing, but you've also helped me develop important skills.</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className='feedback-card'>
                                <p className='mb-0 fw-bold'>Samiya Tanveer</p>
                                <p className='mb-2 fs-xsm'>Digital Marketing</p>
                                <p className='mb-0 fs-sm'>Good effort on students, complete guidance in our students.</p>
                                <div className='d-flex align-items-center mt-2 text-golden'>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    {/* <i class="fa-solid fa-star"></i> */}
                                </div>
                            </div>
                        </div>
                    </marquee>
                </div>
            </div>

            <div className='container-fluid py-5 px-3 bg-purple text-white contact-us-section' id="contactUs">
                <div className='row py-5 ps-md-5'>
                    <div className='col-md-4 px-md-5 mb-5'>
                        <h5 className='fw-bold'>Labbaik IT Services</h5>
                        <p className='mb-0 pt-2'>Pakistan's premier State-of-the-Art, comprehensive Tech Up-Skill Program.<br></br>

Our mission is to empower youth with advanced technical skills, enabling them to make impactful contributions to Islam, Pakistan, and the community's well-being.</p>
                    
                    <p className='pt-3 mb-1 fw-600'>Our Inspiration</p>
                    {/* <p>Qaid e Milat e Islamia Pakar e Gairat o Hamiyaat Sheikhul Hadees Wa Tafseer AmeerulMujahideen Allama Khadim Hussain Rizvi (Rehmatullah Alaye)</p> */}
                    <p>
                    فنا فی الرسول امیر المجاہدین امام مجدد شیخ الحدیث والتفسیر حضرتِ علامہ مولانا حافظ خادم حسین رضوی نور اللہ مرقدہ</p>
                    </div>

                    <div className='col-md mb-5'>
                        <h5>Courses</h5>
                        <p className='mb-1 pt-2'>Web Development</p>
                        <p className='mb-1'>Graphic Designing</p>
                        <p className='mb-1'>Digital Marketing</p>
                        <p className='mb-1'>WordPress Development</p>
                        <p className='mb-1'>Content Writing</p>
                        <p className='mb-1'>SEO</p>
                        <p className='mb-1'>Daraz Ecommerce</p>
                    </div>

                    <div className='col-md mb-5'>
                        <h5>Platforms</h5>
                        <p className='mb-1 pt-2'>Labbaik IT Services</p>
                        <p className='mb-1'><a className='text-white text-decoration-none' href="https://khrewsofficial.com/" target='blank'>KHREWS</a></p>
                        <p className='mb-1'><a className='text-white text-decoration-none' href="https://www.youtube.com/@letsmdcat" target='blank'>Labbaik Entry Test</a></p>
                    </div>

                    <div className='col-md mb-4'>
                        <h5>Contact Us</h5>
                        <p className='mb-1 pt-2'><a className='text-white text-decoration-none' href="mailto:labbaikitservices@gmail.com" target='blank'>labbaikitservices@gmail.com</a></p>
                        <p className='mb-1'><a className='text-white text-decoration-none' href="https://www.whatsapp.com/channel/0029VaKqnas05MUeKRfdf83v" target='blank'>LITS WhatsApp Channel</a></p>
                        <p className='mb-1'><a className='text-white text-decoration-none' href="https://www.youtube.com/@labbaikitservice" target='blank'>LITS Youtube Channel</a></p>
                        <p className='mb-1 mt-4 fw-600'>For any Queries & Suggestions</p>
                        <p className='mb-0 fs-xsm'>Fill out the google form & submit we will respond your query as soon as possible.</p>
                        <a className='text-white fs-xsm mt-2' href="https://forms.gle/B46jjftDsJVGKMAN8" target='blank'>https://forms.gle/B46jjftDsJVGKMAN8</a>
                    </div>
                </div>

                <div className='row ps-md-5'>
                    <div className='col ps-md-5'>
                        <p className='mb-0 pt-3 border-top'>&#169; All Rights are Reserved - 2024</p>
                    </div>
                </div>
            </div>


        </>
    )
}

export default WebLandingPage