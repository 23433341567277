import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home.js';
import Registration from './pages/Registration/Registration.js';
import Main from './pages/Main/Main.js';
// import Header from './pages/Header/Header.js';
// import Footer from './pages/Footer/Footer.js';
import Announcements from './pages/Announcements/Announcements.js';
import Steps from './pages/Steps/Steps.js';
import Results from './pages/Results/Results.js';
import SialkotResult from './pages/SialkotResult/SialkotResult.js';
import SargodhaResult from './pages/SargodhaResult/SargodhaResult.js';
import GujranwalaResult from './pages/GujranwalaResult/GujranwalaResult.js';
import RawalpindiResult from './pages/RawalpindiResult/RawalpindiResult.js';
import LahoreResult from './pages/LahoreResult/LahoreResult.js';
import KarachiResult from './pages/KarachiResult/KarachiResult.js';
import ChakwalResult from './pages/ChakwalResult/ChakwalResult.js';
import OnlineResult from './pages/OnlineResult/OnlineResult.js';
import FinalITShortlistedFemale from './pages/FinalITShortlistedFemale/FinalITShortlistedFemale.js';
import FinalITShortlisted from './pages/FinalITShortlisted/FinalITShortlisted.js';
import WebLandingPage from './pages/WebLandingPage/WebLandingPage.js';
import LMS from './pages/LMS/LMS.js';
import Login from './pages/Login/Login.js';
import CourseContentGraphicDesigning from './pages/CourseContentGraphicDesigning/CourseContentGraphicDesigning.js';
import CourseContentContentWriting from './pages/CourseContentContentWriting/CourseContentContentWriting.js'
import CourseContentDaraz from './pages/CourseContentDaraz/CourseContentDaraz.js';
import CourseContentDigitalMarketing from './pages/CourseContentDigitalMarketing/CourseContentDigitalMarketing.js'
import CourseContentSeo from './pages/CourseContentSeo/CourseContentSeo.js'
import CourseContentWebDevelopment from './pages/CourseContentWebDevelopment/CourseContentWebDevelopment.js'
import CourseContentWordPress from './pages/CourseContentWordPress/CourseContentWordPress.js';

function App() {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        /* this route was used initially for the registration landing page and all dependent routes */
        {/* <Route path="/" element={<Home />}></Route>
        <Route path="/register" element={<Registration />}></Route>
        <Route path="/details" element={<Main />}></Route>
        <Route path="/announcements" element={<Announcements />}></Route>
        <Route path="/join-community" element={<Steps />}></Route>
        <Route path="/result" element={<Results />}></Route>
        <Route path="/chakwal-result" element={<ChakwalResult />}></Route>
        <Route path="/sialkot-result" element={<SialkotResult />}></Route>
        <Route path="/sargodha-result" element={<SargodhaResult />}></Route>
        <Route path="/gujranwala-result" element={<GujranwalaResult />}></Route>
        <Route path="/rawalpindi-result" element={<RawalpindiResult />}></Route>
        <Route path="/lahore-result" element={<LahoreResult />}></Route>
        <Route path="/karachi-result" element={<KarachiResult />}></Route>
        <Route path="/online-result" element={<OnlineResult />}></Route>
        <Route path="/final-list-female" element={<FinalITShortlistedFemale />}></Route>
        <Route path="/final-list-male" element={<FinalITShortlisted />}></Route> */}
        /* --- */

        /* new routes for re designed website */
        <Route path='/' element={<WebLandingPage />}></Route>
        <Route path='/lms-login' element={<Login />}></Route>
        <Route path='/lms-dashboard' element={<LMS />}></Route>
        <Route path='/content-writing-course' element={<CourseContentContentWriting />}></Route>
        <Route path='/daraz-ecommerce-course' element={<CourseContentDaraz />}></Route>
        <Route path='/digital-marketing-course' element={<CourseContentDigitalMarketing />}></Route>
        <Route path='/graphic-designing-course' element={<CourseContentGraphicDesigning />}></Route>
        <Route path='/seo-course' element={<CourseContentSeo />}></Route>
        <Route path='/web-development-course' element={<CourseContentWebDevelopment />}></Route>
        <Route path='/wordpress-development-course' element={<CourseContentWordPress />}></Route>

        /* --- */
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
