import React from 'react'
import { Link } from 'react-router-dom'

function CourseContentWebDevelopment() {
  return (
    <>
      <div className='container py-4'>
        <div className='row'>
          <Link className='text-decoration-none text-dark mb-4' to='/lms-dashboard'><p className='fs-xsm mb-0 mt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>Go Back to Dashboard</p></Link>

          <h5 className='fw-bold mb-3'>Web Development</h5>
        </div>

        <div className='row'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Vto8g8EuBA8?list=PL8WtteUy9eZLRf7IMkVz_Yo3uppng3tje" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/eScJ8tslz4c?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/ULE5ON3uyiM?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/wYGralLuqR0?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/9Cx7TYsdKCE?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/YFQqGoDnodY?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/yfYBlp-JG1E?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/lkZSE7CP-mM?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/au6VBmkQAR4?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/OMeiURdz1BY?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/pfweYDOHwJc?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/CSlh1oqV1nY?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/46chhIMOy1o?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/F_xcdeiRfcI?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/T00IybDM-qA?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/tx8RKwLz_Ko?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/sf1RHWKJsXA?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/nNmr1WnP9jQ?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/Z8lVrMIJQFY?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/FKwhgDIqt2M?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/x3TucwWWjdw?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/hW_6vtpaThM?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/cHEd-yfIIys?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/Kie3w2lG9Gg?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/3tg7Fo6V22w?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/357-FEA3RN8?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/Y58FzVez718?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>
        </div>

        <div className='row mt-md-3 mt-0'>
          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 02' src="https://www.youtube.com/embed/Imu54AXboh0?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 01' src="https://www.youtube.com/embed/RL43pJFWARM?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div>

          {/* <div className='col-md-4 col-12'>
            <iframe width="100%" height="232" title='class 03' src="https://www.youtube.com/embed/Y58FzVez718?list=PL8WtteUy9eZKP4gE9XqhxtjzKM8va_bna" allowFullScreen={true}></iframe>
          </div> */}
        </div>

      </div>
    </>
  )
}

export default CourseContentWebDevelopment