import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        // Hardcoded credentials
        const validUsername = 'student@labbaikitservices.com';
        const validPassword = 'lits@2024';

        const validUsername1 = 'admin@labbaikitservices.com';
        const validPassword1 = 'lits@admin';

        if (username === validUsername && password === validPassword ||  username === validUsername1 && password === validPassword1) {
            // Redirect to another page (e.g., '/dashboard')
            navigate('/lms-dashboard');
        } else {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="container-fluid">
            <div className="row align-items-center justify-content-center vh-100 bg-purple text-white">
                <div className="col-md-6 maxW-520px px-4">
                    <h3 className='fw-600 my-0'>LLMS</h3>
                    <p className='mt-0 mb-3'>Labbaik Learning Management System</p>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="email"
                                className="form-control"
                                id="username"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {error && <p className="text-danger my-1">{error}</p>}
                        <button type="submit" className="btn btn-success bg-sharp-green btn-block mt-3">
                            Login & Start learning
                        </button>
                    </form>

                    {/* <p className='fs-xsm mt-5 pt-3'>Return to the Official Website by clicking here.</p> */}
                    <Link className='text-decoration-none text-white' to='/'><p className='fs-xsm mb-0 mt-5 pt-3'><i class="fa-solid fa-circle-arrow-left me-2"></i>labbaikitservices.com</p></Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
